@tailwind base;
@tailwind components;
@tailwind utilities;

.button, button {
  -webkit-tap-highlight-color: rgba(0, 0, 0,0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Nexdos */
  font-family: "PT Sans", sans-serif;
  width: calc(100vw - (100vw - 100%));
  /*hyphens: auto;*/
}

html {
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Nexdos */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans Narrow", sans-serif;
}

.plotly-notifier {
  opacity: 0 !important;
}

@media print {
  h2 {
    page-break-before: always;
  }

  h2+, *+h2 {
    page-break-before: avoid;
  }
}

@layer base {
  body {
    @apply text-gray-700;
  }

  a,
  .like-anchor {
    text-transform: inherit;
    @apply text-teal-1 font-black cursor-pointer;
  }

  a:hover,
  a:active,
  .like-anchor:hover,
  .like-anchor:active {
    @apply text-teal;
  }

  table {
    @apply w-full;
  }

  th,
  td {
    @apply p-2 first:rounded-l-lg first:pl-4 last:rounded-r-lg last:pr-4;
  }

  thead th {
    @apply bg-gray-200;
  }

  tbody tr:nth-child(even) {
    @apply bg-gray-50;
  }

  tbody tr.selectable {
    @apply hover:cursor-pointer hover:bg-gray-100;
  }

  figcaption {
    @apply text-sm;
  }

  /*label {*/
  /*  @apply uppercase text-xs font-bold;*/
  /*}*/

  /*input,*/
  /*select {*/
  /*  @apply rounded-xl block bg-gray-100 p-2 px-3 mt-1 mb-4 w-full;*/
  /*}*/

  /*input:not([disabled]),*/
  /*select:not([disabled]) {*/
  /*  @apply hover:bg-gray-200;*/
  /*}*/

  /*input::after {*/
  /*  @apply mr-2;*/
  /*}*/

  /*select {*/
  /*  background-image:*/
  /*          linear-gradient(45deg, transparent 50%, gray 50%),*/
  /*          linear-gradient(135deg, gray 50%, transparent 50%);*/
  /*  background-position:*/
  /*          calc(100% - 20px) calc(1em + 2px),*/
  /*          calc(100% - 15px) calc(1em + 2px);*/
  /*  background-size:*/
  /*          5px 5px,*/
  /*          5px 5px;*/
  /*  background-repeat: no-repeat;*/
  /*  @apply appearance-none;*/
  /*}*/

  /*[disabled] {*/
  /*  @apply bg-gray-100 text-gray-400;*/
  /*}*/

  figcaption {
    @apply text-center mt-1;
  }

  .post-content {
    @apply flex flex-col gap-4;
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  .post-content > * {
    max-width: 1000px;
  }

  .post-content > * > br {
    @apply block mb-0;
  }

  .post-content > h1 {
    @apply text-4xl;
  }

  .post-content > h2 {
    @apply text-3xl;
  }

  .post-content > h3 {
    @apply text-2xl;
  }

  .post-content > h4 {
    @apply text-xl;
  }

  .post-content > hr {
    @apply my-8
  }

  /*.post-content > h1,*/
  /*.post-content > h2,*/
  /*.post-content > h3,*/
  /*.post-content > h4 {*/
  /*  @apply mt-8;*/
  /*}*/

  .post-content > figure.kg-card {
    @apply flex items-center justify-center m-8;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container  {
    @apply border hover:text-teal rounded-xl p-4 flex gap-8 w-fit bg-teal-2 bg-opacity-0 hover:bg-opacity-5;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > div {
    @apply flex-grow-0 h-full;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > .kg-file-card-contents {
    @apply text-base font-normal;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > .kg-file-card-contents > .kg-file-card-title {
    @apply text-lg font-bold;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > .kg-file-card-contents > .kg-file-card-caption {
    @apply flex gap-4 text-gray-700 mb-1;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > .kg-file-card-contents > .kg-file-card-metadata{
    @apply text-gray-700;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > .kg-file-card-contents > .kg-file-card-metadata > * {
    @apply relative break-words inline mr-4;
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > .kg-file-card-contents > .kg-file-card-metadata > .kg-file-card-filename:not(:last-child)::after  {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(0.75rem);
    content:"•";
  }

  .post-content > .kg-card.kg-file-card > .kg-file-card-container > .kg-file-card-icon {
    @apply aspect-square h-10;
  }

  .arrow-flip {
    animation: arrow-flip ease forwards 300ms;
  }

  @keyframes arrow-flip {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(-1);
    }
  }

  #root {
    @apply w-full;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  h4 {
    @apply text-xl;
  }

  h5 {
    @apply text-lg;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    @apply mt-0;
  }


  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child,
  ul:last-child,
  ol:last-child {
    @apply mb-0;
  }

  ol, ul {
    @apply ml-6;
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  .flex > * {
    @apply my-0;
  }

  strong,
  a {
    @apply font-bold;
  }

  button {
    -webkit-tap-highlight-color: transparent;
  }

  button[disabled] {
    @apply opacity-50;
  }
}

.MuiDataGrid-row {
  @apply cursor-pointer;
}

div.inline-localized-text > *,
div.inline-localized-text > * > *,
div.inline-localized-text > * > * > *,
div.inline-localized-text > * > * > * > * {
  @apply inline;
}

.modal-dissolve {
  animation: forwards ease 300ms modal-dissolve;
}

@keyframes modal-dissolve {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fade-in-80 {
  animation: forwards ease 300ms fade-in-80-key;
}

@keyframes fade-in-80-key {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

.modal-dissolve-top-down {
  animation: forwards ease 300ms modal-dissolve-top-down;
}

@keyframes modal-dissolve-top-down {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}


.modal-dissolve-bottom-up {
  animation: forwards ease 300ms modal-dissolve-bottom-up;
}

@keyframes modal-dissolve-bottom-up {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.dissolve-left-right {
  animation: forwards ease 300ms dissolve-left-right;
}

@keyframes dissolve-left-right {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.true-dissolve {
  animation: forwards ease 300ms true-dissolve;
}

@keyframes true-dissolve {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

